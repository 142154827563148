import * as React from 'react'
import clsx from 'clsx'
import { CommonComponentProps } from '@controls/types'
import checkedIcon from '@assets/icons/checkGreen.svg'
import styles from './CheckedText.module.css'

export const CheckedText: React.FC<CommonComponentProps> = ({ className, children }) => (
  <div className={clsx(styles.checkedTextContainer, className)}>
    <img src={checkedIcon} alt="check mark" />
    {children}
  </div>
)
